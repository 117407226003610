import { render, staticRenderFns } from "./rollCall.vue?vue&type=template&id=28b07a17&scoped=true&"
import script from "./rollCall.vue?vue&type=script&lang=js&"
export * from "./rollCall.vue?vue&type=script&lang=js&"
import style0 from "./rollCall.vue?vue&type=style&index=0&id=28b07a17&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b07a17",
  null
  
)

export default component.exports