<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :navList="navList" :typeList="[]" :navCurrentId="navCurrentId">
            <div slot="search" style="display: none"></div>
        </Header>
        <div class="bg"></div>
        <a-spin :spinning="spinning">
            <div class="content">
                <div class="title strong t-c">{{ pageHeaderInfo.c_name }}</div>
                <div class="sub-header t-c">{{ pageHeaderInfo.b_name }}</div>
                <div class="time t-c" v-if="pageHeaderInfo.RecommendStartTime">换届时间：{{ pageHeaderInfo.RecommendStartTime | formatDate('yyyy年mm月dd日') }}</div>
                <div class="roll-call-box">
                    <div class="line flex x-left y-top" v-for="(item,index) in pageData.data" :key="index">
                        <div class="key">{{ item.name }}</div>
                        <span class="strong">:</span>
                        <div class="name flex x-left flex-wrap">
                            <div :class="['n-i',pageHeaderInfo.c_name.indexOf('新疆') > -1 ? 'lang': '']" v-for="(data,idx) in item.list" :key="idx" @click="handleToPersonPage(idx)">{{ data }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </a-spin>

        <Footer></Footer>
    </div>
</template>

<script>
import {exportLogo , expertNavList } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer.vue";
export default {
    name: "rollCall",
    components : {
        Header , Footer
    },
    data(){
        return {
            logoUrl : exportLogo ,
            navList : expertNavList,
            navCurrentId : 5,
            pageData : [],
            pageHeaderInfo : {},
            spinning : true,
        }
    },
    created() {
        this.queryData = this.$route.query;
        this.getPageData();
    },
    mounted() {
        let headerHeight = document.querySelector('.header').offsetHeight;
        let footerHeight = document.querySelector('.footer').offsetHeight;
        let countHeight = parseInt(footerHeight) + parseInt(headerHeight) + 56;
        document.querySelector('.content').style.minHeight = `calc(100vh - ${countHeight}px)`;
    },
    methods : {
        getPageData(){
            let { BranchId,branchId , CouncilID, PeriodID } = this.queryData
            BranchId = BranchId || branchId;
            let data = {
                BranchId,
                CouncilID,
                PeriodID
            }
            this.request.post('MembersDetails',data).then(res=>{
                console.log(res.data);
                this.pageData = res.data;
                this.pageHeaderInfo = res.info;
                this.spinning = false;
            })
        },
        handleToPersonPage(personId){
            this.$router.push({
                path: '/person-detail',
                query: {
                    uid : personId
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .box{
        position: relative;
        .bg{
            position: absolute;
            width: 100%;
            height: 594px;
            //渐变色
            background: linear-gradient(to bottom,#E5EDFE,#EAF0FF);
            z-index: -999;
            top: 120px;
        }
    }
    .content{
        width: 1240px;
        margin: 0 auto;
        min-height: 500px;
        .title{
            color: #333333;
            font-size: 20px;
            margin-top: 10px;
        }
        .sub-title{
            color: #666666;
            font-size: 14px;
            margin-top: 10px;
        }
        .time{
            color: #999999;
            font-size: 12px;
        }
        .roll-call-box{
            margin: 30px auto 50px;
            width: 100%;
            background: #ffffff;
            border-radius: 10px;
            padding: 30px 50px;
            min-height: 200px;
            .line{
                margin-bottom: 10px;
                .key{
                    flex-shrink: 0;
                    font-size: 14px;
                    font-weight: bold;
                    width: 90px;
                    text-align: justify;
                    text-align-last: justify;
                    text-justify: distribute-all-lines;
                }
                span{
                    margin-left: 6px;
                }
                .name{
                    .n-i{
                        min-width: 64px;
                        text-align: justify;
                        text-align-last: justify;
                        text-justify: distribute-all-lines;
                        cursor: pointer;
                        padding: 0 10px;
                        margin-bottom: 6px;
                    }
                    .lang{
                        /*background: #f8fbfd;
                        border-radius: 6px;
                        border: 1px solid #edeef4;
                        margin-left: 10px;*/
                        text-align: left;
                        min-width: max-content;
                    }
                }
            }
        }
    }
</style>